// import { addQueryVerb } from "arquero/dist/types/query/query";
import * as aq from "arquero";

aq.addFunction("parseDate", function (date) {
  return new Date(date);
});

export function filterCardCountTable(table, filters) {
  let filteredTable = table;

  filters = { ...filters, [filters["geo_type"]]: filters["geography"] };
  // delete filters["geo_type"];
  // delete filters["geography"];

  // filteredTable = filteredTable.params(filters).filter((d, $) => {
  //   let bools = [];
  //   let keys = Object.keys($);
  //   for (let index = 0; index < keys.length; index++) {
  //     bools.push(d[keys[index]] === $[keys[index]]);
  //   }
  //   return bools.include(false);
  // }); // I guess this doesn't work.

  if (filters.age_range !== "any") {
    filteredTable = filteredTable
      .params({ age_range: filters.age_range })
      .filter((d, $) => d.age_range === $.age_range);
  }

  if (!!filters["county_fips"] && filters["county_fips"] !== "any") {
    filteredTable = filteredTable.params(filters).filter((d, $) => {
      return d.county_fips === $.county_fips;
    });
  }

  if (!!filters["city"] && filters["city"] !== "any") {
    filteredTable = filteredTable.params(filters).filter((d, $) => {
      return d.city === $.city;
    });
    console.log(filteredTable);
  }

  if (filters.gender !== "any") {
    filteredTable = filteredTable
      .params(filters)
      .filter((d, $) => d.gender === $.gender);
  }

  if (filters.race !== "any") {
    filteredTable = filteredTable
      .params(filters)
      .filter((d, $) => d.race === $.race);
  }

  if (filters.start_date !== undefined) {
    filteredTable = filteredTable.params(filters).filter((d, $) => {
      return aq.op.parseDate(d.completed_date) >= aq.op.parseDate($.start_date);
    });
  }

  if (filters.end_date !== undefined) {
    filteredTable = filteredTable.params(filters).filter((d, $) => {
      return aq.op.parseDate(d.completed_date) <= aq.op.parseDate($.end_date);
    });
  }

  return filteredTable;
}
