import "./App.scss";
import "leaflet/dist/leaflet.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Storage } from "@aws-amplify/storage";
import { Routes, Route, Link } from "react-router-dom";
import AppContext from "./Shared/Context/AppContext";
import Summary from "./Pages/Summary";
import Breakdowns from "./Pages/Breakdowns";
import { useEffect, useState, useRef } from "react";
import { fromCSV } from "arquero";
import { ObjectParam } from "use-query-params";
import useAllQueryParams from "./Shared/Hooks/useAllQueryParams";
import { encodeQueryParams, StringParam } from "serialize-query-params";
import { stringify } from "query-string";
import { filterCardCountTable } from "./Shared/Utils.js";
import L from "leaflet";

(function () {
  // fix leaflet ugly one pixel issue.
  var originalInitTile = L.GridLayer.prototype._initTile;
  L.GridLayer.include({
    _initTile: function (tile) {
      originalInitTile.call(this, tile);

      var tileSize = this.getTileSize();

      tile.style.width = tileSize.x + 1 + "px";
      tile.style.height = tileSize.y + 1 + "px";
    },
  });
})();

Storage.configure({
  customPrefix: {
    public: "data/",
  },
});

let aqFormatOpts = {
  parse: {
    AIANNHCE: String,
    county_fips: (val) => {
      let parsedVal = val.toString();
      parsedVal = parsedVal === "04" ? "04000" : parsedVal;
      return parsedVal;
    },
    completed_date: (val) => {
      return new Date(val).getTime();
    },
    gender: String,
    race: String,
    age_range: String,
  },
};

function App({ signOut, user }) {
  let CardCountTableRef = useRef(null);
  let [loading, setLoading] = useState(true);
  let [Filters] = useAllQueryParams("filters", ObjectParam);
  let [FilteredCardCountTable, setFilteredCardCountTable] = useState(null);

  useEffect(() => {
    if (CardCountTableRef.current === null) {
      Storage.get("CardCountTable_latest.csv", { download: true }).then(
        (file) => {
          file.Body.text().then((t) => {
            CardCountTableRef.current = fromCSV(t, aqFormatOpts);
            setFilteredCardCountTable(
              filterCardCountTable(CardCountTableRef.current, Filters)
            );
            setLoading(false);
          });
        }
      );
    } else {
      setFilteredCardCountTable(
        filterCardCountTable(CardCountTableRef.current, Filters)
      );
    }
  }, [Filters]);

  // console.log();
  let filterString = !!Filters
    ? "?" +
      stringify(
        encodeQueryParams(
          {
            geo_type: StringParam,
            geography: StringParam,
            age_range: StringParam,
            gender: StringParam,
            race: StringParam,
            start_date: StringParam,
            end_date: StringParam,
          },
          Object.keys(Filters)
            .filter((key) => {
              return Filters[key] !== "any" && Filters[key] !== undefined;
            })
            .reduce((cur, key) => {
              return Object.assign(cur, { [key]: Filters[key] });
            }, {})
        )
      )
    : "";
  if (loading) return "loading";
  return (
    <AppContext.Provider
      value={{
        CardCountTable: CardCountTableRef.current,
        FilteredCardCountTable,
      }}
    >
      <div>
        <header className="d-flex align-items-center justify-content-between p-4 bg-dark">
          <nav>
            <Link className="mr-3 text-white" to={"/" + filterString}>
              Summary
            </Link>
            <Link
              className="mr-3 text-white"
              to={"/breakdowns/" + filterString}
            >
              Breakdowns
            </Link>
            <Link className="mr-3 text-white" to="/monitoring">
              Monitoring
            </Link>
            <Link className="mr-3 text-white" to="/reports">
              Reports
            </Link>
          </nav>
          <button
            className="btn btn-sm btn-gray-200 text-gray-800"
            onClick={signOut}
          >
            Sign out
          </button>
        </header>
        <main>
          <Routes>
            <Route path="/" element={<Summary />}></Route>
            <Route path={"/breakdowns/*"} element={<Breakdowns />}></Route>
            <Route path="*" element={<div>404</div>}></Route>
          </Routes>
        </main>
      </div>
    </AppContext.Provider>
  );
}

export default withAuthenticator(App);
