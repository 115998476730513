export const GeoTypes = {
  county_fips: "County",
  city: "City",
};

export const county_fips = {
  "04000": "Arizona (No County)",
  "04001": "Apache County",
  "04003": "Cochise County",
  "04005": "Coconino County",
  "04007": "Gila County",
  "04009": "Graham County",
  "04011": "Greenlee County",
  "04012": "La Paz County",
  "04013": "Maricopa County",
  "04015": "Mohave County",
  "04017": "Navajo County",
  "04019": "Pima County",
  "04021": "Pinal County",
  "04023": "Santa Cruz County",
  "04025": "Yavapai County",
  "04027": "Yuma County",
};

export const city = {
  "APACHE JUNCTION": "Apache Junction",
  AVONDALE: "Avondale",
  BALTIMORE: "Baltimore",
  // "BLANK--": "Blank--",
  CHANDLER: "Chandler",
  COOLIDGE: "Coolidge",
  "EL MIRAGE": "El Mirage",
  FLAGSTAFF: "Flagstaff",
  "FOUNTAIN HILLS": "Fountain Hills",
  GLENDALE: "Glendale",
  GUADALUPE: "Guadalupe",
  HOLBROOK: "Holbrook",
  "INDIAN WELLS": "Indian Wells",
  LAVEEN: "Laveen",
  LEUPP: "Leupp",
  MESA: "Mesa",
  NOGALES: "Nogales",
  PAYSON: "Payson",
  PEORIA: "Peoria",
  PHOENIX: "Phoenix",
  "SAN LUIS": "San Luis",
  SCOTTSDALE: "Scottsdale",
  SOMERTON: "Somerton",
  "SUN CITY WEST": "Sun City West",
  SURPRISE: "Surprise",
  TEMPE: "Tempe",
  "TUBA CITY": "Tuba City",
  TUCSON: "Tucson",
  WILLIAMS: "Williams",
  "WINDOW ROCK": "Window Rock",
  YUMA: "Yuma",
  TOLLESON: "Tolleson",
  "ARIZONA CITY": "Arizona City",
  "CASA GRANDE": "Casa Grande",
  CORONA: "Corona",
  DOUGLAS: "Douglas",
  "EL PASO": "El Paso",
  "FORT DEFIANCE": "Fort Defiance",
  GADSDEN: "Gadsden",
  GILBERT: "Gilbert",
  GOODYEAR: "Goodyear",
  JACKSON: "Jackson",
  KAYENTA: "Kayenta",
  "LITCHFIELD PARK": "Litchfield Park",
  MARANA: "Marana",
  MARICOPA: "Maricopa",
  "NEW RIVER": "New River",
  OLYMPIA: "Olympia",
  PAGE: "Page",
  "QUEEN CREEK": "Queen Creek",
  "SAN MANUEL": "San Manuel",
  SOC: "Soc",
  "ST. GEORGE": "St. george",
  "ST. PAUL": "St. paul",
  THOMPSON: "Thompson",
  TONOPAH: "Tonopah",
  TUCKER: "Tucker",
  TULSA: "Tulsa",
  KAIBETO: "Kaibeto",
  SELLS: "Sells",
  CONCHO: "Concho",
  WILLCOX: "Willcox",
  BUCKEYE: "Buckeye",
  PINON: "Pinon",
  VAIL: "Vail",
  ELOY: "Eloy",
  SACATON: "Sacaton",
  AMADO: "Amado",
  ANCHORAGE: "Anchorage",
  ANTHEM: "Anthem",
  BENSON: "Benson",
  "BOCA RATON": "Boca Raton",
  BOISE: "Boise",
  "BULLHEAD CITY": "Bullhead City",
  "CARSON CITY": "Carson City",
  CATALINA: "Catalina",
  "CAVE CREEK": "Cave Creek",
  CHICAGO: "Chicago",
  CONROE: "Conroe",
  DENTON: "Denton",
  DENVER: "Denver",
  FLORENCE: "Florence",
  "FORT MOHAVE": "Fort Mohave",
  "FORT WORTH": "Fort Worth",
  FRESNO: "Fresno",
  GANADO: "Ganado",
  "GREEN VALLEY": "Green Valley",
  GREENFIELD: "Greenfield",
  HONOLULU: "Honolulu",
  KINGMAN: "Kingman",
  "LA QUINTA": "La Quinta",
  "LAKE FOREST": "Lake Forest",
  "LAKE HAVASU CITY": "Lake Havasu City",
  LANCASTER: "Lancaster",
  LANSING: "Lansing",
  "LAS VEGAS": "Las Vegas",
  LAUREN: "Lauren",
  MAMMOTH: "Mammoth",
  MCNARY: "Mcnary",
  MILWAUKEE: "Milwaukee",
  MISSOULA: "Missoula",
  "NEW YORK CITY": "New York City",
  NORWALK: "Norwalk",
  "PALM DESERT": "Palm Desert",
  "PARADISE VALLEY": "Paradise Valley",
  PATAGONIA: "Patagonia",
  POUGHKEEPSIE: "Poughkeepsie",
  PRESCOTT: "Prescott",
  QUARTZSITE: "Quartzsite",
  "RIM ROCK": "Rim Rock",
  "RIO RICO": "Rio Rico",
  SAHUARITA: "Sahuarita",
  "SAN BERNARDINO": "San Bernardino",
  "SANTAN VALLEY": "Santan Valley",
  "SIERRA VISTA": "Sierra Vista",
  "ST. MICHAELS": "St. michaels",
  STANFIELD: "Stanfield",
  STOCKTON: "Stockton",
  "SUN CITY": "Sun City",
  SUNRISE: "Sunrise",
  TAMPA: "Tampa",
  TEMECULA: "Temecula",
  TREASURE: "Treasure",
  TURNER: "Turner",
  UNION: "Union",
  UPLAND: "Upland",
  "VALLEY FARM": "Valley Farm",
  VISALIA: "Visalia",
  WADDELL: "Waddell",
  WELLTON: "Wellton",
  WHITTIER: "Whittier",
  WICKENBURG: "Wickenburg",
  WINSLOW: "Winslow",
  WINTERHAVEN: "Winterhaven",
  WITTMANN: "Wittmann",
  BAPCHULE: "Bapchule",
  TONALEA: "Tonalea",
  SEDONA: "Sedona",
  GLOBE: "Globe",
  SAFFORD: "Safford",
  PARKER: "Parker",
  AGUILA: "Aguila",
  YOUNGTOWN: "Youngtown",
  "SHOW LOW": "Show Low",
  "KEAMS CANYON": "Keams Canyon",
  "ORO VALLEY": "Oro Valley",
  "GOLD CANYON": "Gold Canyon",
  "BLACK CANYON CITY": "Black Canyon City",
  "CHINO VALLEY": "Chino Valley",
  PAULDEN: "Paulden",
  "PRESCOTT VALLEY": "Prescott Valley",
  // 1: "1",
  AIN: "Ain",
  AJO: "Ajo",
  ANAHEIM: "Anaheim",
  BAKERSFIELD: "Bakersfield",
  BESSEMER: "Bessemer",
  CAMERON: "Cameron",
  CHINLE: "Chinle",
  COTTONWOOD: "Cottonwood",
  DETROIT: "Detroit",
  HOUSTON: "Houston",
  "HUNTERS POINT": "Hunters Point",
  IMPERIAL: "Imperial",
  KILLEEN: "Killeen",
  "MANHATTAN BEACH": "Manhattan Beach",
  MEMPHIS: "Memphis",
  MIAMI: "Miami",
  MORENCI: "Morenci",
  NAZLINI: "Nazlini",
  OAKLAND: "Oakland",
  "PEACH SPRINGS": "Peach Springs",
  "RED ROCK": "Red Rock",
  SALOME: "Salome",
  "SAN CORS": "San Cors",
  "SAN FERNANDO": "San Fernando",
  SARATON: "Saraton",
  "WHITE RIVER": "White River",
  WHITERIVER: "Whiteriver",
  BOWIE: "Bowie",
  PERIDOT: "Peridot",
  WITTMAN: "Wittman",
  AION: "Aion",
  ALBUQUERQUE: "Albuquerque",
  "CORDES LAKES": "Cordes Lakes",
  FAIRFAX: "Fairfax",
  "HUACHUCA CITY": "Huachuca City",
  KYKOTSMOVI: "Kykotsmovi",
  "MONUMENT VALLEY": "Monument Valley",
  TO: "To",
  VISTA: "Vista",
  WENDEN: "Wenden",
  "SUN LAKES": "Sun Lakes",
  "DOLAN SPRINGS": "Dolan Springs",
  "KYKOTSMOVI VILLAGE": "Kykotsmovi Village",
  CLAYPOOL: "Claypool",
  CORNVILLE: "Cornville",
  "GOLDEN VALLEY": "Golden Valley",
  "GRAND RAPIDS": "Grand Rapids",
  "LAS CRUCES": "Las Cruces",
  "LOS ANGELES": "Los Angeles",
  LUKACHUKAI: "Lukachukai",
  "SOUTH TUCSON": "South Tucson",
  VIVIA: "Vivia",
  ACHPE: "Achpe",
  "ASH FORK": "Ash Fork",
  ATHENS: "Athens",
  ATLANTA: "Atlanta",
  CLIFTON: "Clifton",
  "COLORADO SPRINGS": "Colorado Springs",
  DANBURY: "Danbury",
  DATELAND: "Dateland",
  ELGIN: "Elgin",
  FARMINGTON: "Farmington",
  "FORT MCDOWELL": "Fort Mcdowell",
  ORLANDO: "Orlando",
  PHILADELPHIA: "Philadelphia",
  "SAN DIEGO": "San Diego",
  SEONDA: "Seonda",
  SUNCITY: "Suncity",
  TACNA: "Tacna",
  TAYLORSVILLE: "Taylorsville",
  TUMACACORI: "Tumacacori",
  WINSTON: "Winston",
  "WISCONSIN RAPIDS": "Wisconsin Rapids",
  DENNEHOTSO: "Dennehotso",
  "INSCRIPTION HOUSE": "Inscription House",
  "SAN CARLOS": "San Carlos",
  THATCHER: "Thatcher",
  CASHION: "Cashion",
  KEARNY: "Kearny",
  ANTIOCH: "Antioch",
  NEWARK: "Newark",
  BOSTON: "Boston",
  "CAMP VERDE": "Camp Verde",
  CHAMBERS: "Chambers",
  CLARKDALE: "Clarkdale",
  DEWEY: "Dewey",
  ORACLE: "Oracle",
  PAHRUMP: "Pahrump",
  TUESON: "Tueson",
  WATERFORD: "Waterford",
  WHITECONE: "Whitecone",
  BOERNE: "Boerne",
  CARSON: "Carson",
  ELMIRA: "Elmira",
  "LOS ALAMOS": "Los Alamos",
  "MEXICAN HAT": "Mexican Hat",
  SEATTLE: "Seattle",
  THORNDALE: "Thorndale",
  UNIVERSITY: "University",
  VANTAGE: "Vantage",
  "WIDOW ROCK": "Widow Rock",
  PICACHO: "Picacho",
  SELIGMAN: "Seligman",
  ALLEN: "Allen",
  "APPLE VALLEY": "Apple Valley",
  ARIVACA: "Arivaca",
  ARLINGTON: "Arlington",
  ARTOS: "Artos",
  AURORA: "Aurora",
  AUSTIN: "Austin",
  BAGDAD: "Bagdad",
  BANNING: "Banning",
  BARTLETT: "Bartlett",
  BATAVIA: "Batavia",
  "BATON ROUGE": "Baton Rouge",
  BELLEVUE: "Bellevue",
  BELLINGHAM: "Bellingham",
  BERTHOUD: "Berthoud",
  "BIG BEAR": "Big Bear",
  BILLINGS: "Billings",
  BIRMINGHAM: "Birmingham",
  BLYTHE: "Blythe",
  BOULDER: "Boulder",
  BRENTWOOD: "Brentwood",
  BRIDGEPORT: "Bridgeport",
  BRISTOL: "Bristol",
  BROOKINGS: "Brookings",
  BRUNSWICK: "Brunswick",
  "BRYN MAWR": "Bryn Mawr",
  "BUENA PARK": "Buena Park",
  BUFFALO: "Buffalo",
  "BUFFALO GROVE": "Buffalo Grove",
  CALLAHAN: "Callahan",
  CAMAS: "Camas",
  CANTON: "Canton",
  "CASTRO VALLEY": "Castro Valley",
  CHAMBERSBURG: "Chambersburg",
  CHAMELIN: "Chamelin",
  CHARLOTTE: "Charlotte",
  COLLIERVILLE: "Collierville",
  COVINA: "Covina",
  CRANFORD: "Cranford",
  CROWNPOINT: "Crownpoint",
  "CRYSTAL LAKE": "Crystal Lake",
  DALLAS: "Dallas",
  DANVILLE: "Danville",
  DESTIN: "Destin",
  "FORT HUACHUCA": "Fort Huachuca",
  FULLERTON: "Fullerton",
  GALLUP: "Gallup",
  "GILA BEND": "Gila Bend",
  "GOLD HILL": "Gold Hill",
  HENDERSON: "Henderson",
  HEREFORD: "Hereford",
  INGLEWOOD: "Inglewood",
  KENOSHA: "Kenosha",
  KOMATKE: "Komatke",
  "LAKE OSWEGO": "Lake Oswego",
  LAKEVILLE: "Lakeville",
  LANDISVILLE: "Landisville",
  LITTLETON: "Littleton",
  "LONG BEACH": "Long Beach",
  LORDSBURG: "Lordsburg",
  LYONS: "Lyons",
  MACON: "Macon",
  MACUNGIE: "Macungie",
  "MAPLE GROVE": "Maple Grove",
  NAPERVILLE: "Naperville",
  "OAK PARK": "Oak Park",
  ONTARIO: "Ontario",
  ORANGE: "Orange",
  OREM: "Orem",
  OSWEGO: "Oswego",
  OXNARD: "Oxnard",
  PALMDALE: "Palmdale",
  "PARADISE VALEY": "Paradise Valey",
  PASADENA: "Pasadena",
  PEARCE: "Pearce",
  PITTSBURGH: "Pittsburgh",
  PORTLAND: "Portland",
  REDDING: "Redding",
  RICHMOND: "Richmond",
  RIDGEFIELD: "Ridgefield",
  "RIO RANCHO": "Rio Rancho",
  RIVERSIDE: "Riverside",
  ROCHESTER: "Rochester",
  ROSEVILLE: "Roseville",
  SACRAMENTO: "Sacramento",
  SALINAS: "Salinas",
  "SAN ANTONIO": "San Antonio",
  "SAN FRANCISCO": "San Francisco",
  "SAN MARCOS": "San Marcos",
  "SANTA CLARITA": "Santa Clarita",
  SARASOTA: "Sarasota",
  SNOWFLAKE: "Snowflake",
  SONOITA: "Sonoita",
  SPARKS: "Sparks",
  SPRINGERVILLE: "Springerville",
  "ST. DAVID": "St. david",
  SUNNYVALE: "Sunnyvale",
  SUPERIOR: "Superior",
  TAYLOR: "Taylor",
  TOMBSTONE: "Tombstone",
  TYLER: "Tyler",
  "UNIVERSITY PARK": "University Park",
  VALENCIA: "Valencia",
  WAIPAHU: "Waipahu",
  "WALNUT CREEK": "Walnut Creek",
  WAYNE: "Wayne",
  WINGLER: "Wingler",
  WINNETRA: "Winnetra",
  "WOODLAND HILLS": "Woodland Hills",
  YARNELL: "Yarnell",
  YUCAIPA: "Yucaipa",
  LUPTON: "Lupton",
  BISBEE: "Bisbee",
  BELLEMONT: "Bellemont",
  PIMA: "Pima",
  MORRISTOWN: "Morristown",
  "PALO VERDE": "Palo Verde",
  LAKESIDE: "Lakeside",
  "PINETOP LAKESIDE": "Pinetop Lakeside",
  HOTEVILLA: "Hotevilla",
  "CORONA DE TUCSON": "Corona De Tucson",
  CORTARO: "Cortaro",
  AMARILLO: "Amarillo",
  "BALDWIN PARK": "Baldwin Park",
  BARABOO: "Baraboo",
  BOUSE: "Bouse",
  "BRIGHAM CITY": "Brigham City",
  CALEXICO: "Calexico",
  CHINO: "Chino",
  CROSSVILLE: "Crossville",
  DECATUR: "Decatur",
  HAYDEN: "Hayden",
  HOUCK: "Houck",
  INDIANAPOLIS: "Indianapolis",
  "LA PLATA": "La Plata",
  "MANY FARMS": "Many Farms",
  PARKS: "Parks",
  UNIVERSAL: "Universal",
  VIMA: "Vima",
  VIUM: "Vium",
  WESON: "Weson",
  WEST: "West",
  WINKELMAN: "Winkelman",
  "ROCK POINT": "Rock Point",
  NACO: "Naco",
  BOUNTIFUL: "Bountiful",
  BUCKENE: "Buckene",
  EHRENBERG: "Ehrenberg",
  GREELEY: "Greeley",
  "HUNTINGTON BEACH": "Huntington Beach",
  "LITTLE ROCK": "Little Rock",
  MINNEAPOLIS: "Minneapolis",
  TOLTEC: "Toltec",
  JEDDITO: "Jeddito",
  "ASHLEY FALLS": "Ashley Falls",
  CONTARO: "Contaro",
  CORTANO: "Cortano",
  MINOT: "Minot",
  RENO: "Reno",
  TACOMA: "Tacoma",
  TUBAC: "Tubac",
  YUKYT: "Yukyt",
  BROOMFIELD: "Broomfield",
  CAREFREE: "Carefree",
  "DODGE CITY": "Dodge City",
  FREDONIA: "Fredonia",
  KALAMAZOO: "Kalamazoo",
  SADDLEBROOKE: "Saddlebrooke",
  "SALT LAKE CITY": "Salt Lake City",
  "ST. JOHNS": "St. johns",
  TSAILE: "Tsaile",
  VIIMIA: "Viimia",
  VLILIA: "Vlilia",
  WINFIELD: "Winfield",
  WINSECAN: "Winsecan",
  YUARA: "Yuara",
  ALBANY: "Albany",
  "PINETOP/LAKESIDE": "Pinetop/lakeside",
  TUSAYAN: "Tusayan",
  "CAMPE VERDE": "Campe Verde",
  HUACHUCA: "Huachuca",
  "DEWEY-HUMBOLDT": "Dewey-humboldt",
  "TOWN_OF KEARNY": "Town_of Kearny",
  JEROME: "Jerome",
  "ST JOHNS": "St Johns",
  EAGAR: "Eagar",
  DUNCAN: "Duncan",
  "STAR VALLEY": "Star Valley",
};

export const GeoTypeLabels = {
  county_fips,
  city,
};

export const AgeRanges = {
  0: "-18",
  1: "18-29",
  2: "30-39",
  3: "40-49",
  4: "50-64",
  5: "65+",
};

export const Genders = {
  F: "Female",
  M: "Male",
  X: "Expansive",
};

export const Races = {
  "African-American": "African-American",
  Asian: "Asian",
  Caucasian: "Caucasian",
  Hispanic: "Hispanic",
  "Native American": "Native American",
};
