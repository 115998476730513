import useAllQueryParams from "../Shared/Hooks/useAllQueryParams";
// import AppContext from "../Shared/Context/AppContext";
// import { useContext } from "react";
// import * as aq from "arquero";
import { GeoTypes, GeoTypeLabels } from "../Shared/Labels";

export default function Filters() {
  // let { CardCountTable } = useContext(AppContext);
  let [Filters, setFilters, clearAllFilters] = useAllQueryParams();
  // console.log(Filters["geo_type"]);
  // let uniqueGeographies = CardCountTable.rollup({
  //   uniqueGeographies: aq.op.array_agg_distinct("county_fips"),
  // })
  //   .column("uniqueGeographies")
  //   .get(0);
  return (
    <div className="d-flex justify-content-start align-items-center border-bottom mb-3">
      <div className="form-group mr-2">
        <label
          className={
            (Filters["geo_type"] !== "any" ? "font-weight-bold" : "") + " px-2"
          }
          htmlFor="geo_type"
        >
          Geo Type
        </label>
        <select
          className="form-control"
          name="geo_type"
          id="geo_type"
          value={Filters["geo_type"]}
          onChange={(e) => {
            setFilters({ geography: "any", geo_type: e.target.value });
          }}
        >
          <option value={"any"} key="geotype-any">
            Any
          </option>
          {Object.keys(GeoTypes).map((geotype) => {
            return (
              <option value={geotype} key={"geotype-" + geotype}>
                {GeoTypes[geotype]}
              </option>
            );
          })}
        </select>
      </div>
      {Filters["geo_type"] !== "any" && (
        <div className="form-group mr-2">
          <label
            className={
              (Filters["geography"] !== "any" ? "font-weight-bold" : "") +
              " px-2"
            }
            htmlFor="geography"
          >
            {GeoTypes[Filters["geo_type"]]}
          </label>
          <select
            className="form-control"
            name="geography"
            id="geography"
            value={Filters["geography"]}
            onChange={(e) => {
              setFilters({ geography: e.target.value });
            }}
          >
            <option value={"any"} key="geography-any">
              Any
            </option>
            {Object.keys(GeoTypeLabels[Filters["geo_type"]]).map(
              (geography) => {
                return (
                  <option value={geography} key={"geography-" + geography}>
                    {GeoTypeLabels[Filters["geo_type"]][geography]}
                  </option>
                );
              }
            )}
          </select>
        </div>
      )}
      <div className="form-group mr-2">
        <label
          className={
            (Filters["age_range"] !== "any" ? "font-weight-bold" : "") + " px-2"
          }
          htmlFor="age"
        >
          Age
        </label>
        <select
          className="form-control"
          name="age"
          id="age_range"
          value={Filters["age_range"]}
          onChange={(e) => setFilters({ age_range: e.target.value })}
        >
          <option value="any">Any</option>
          <option value="0">-18</option>
          <option value="1">18-29</option>
          <option value="2">30-39</option>
          <option value="3">40-49</option>
          <option value="4">50-64</option>
          <option value="5">65+</option>
        </select>
      </div>
      <div className="form-group mr-2">
        <label
          className={
            (Filters["gender"] !== "any" ? "font-weight-bold" : "") + " px-2"
          }
          htmlFor="gender"
        >
          Gender
        </label>
        <select
          className="form-control"
          name="gender"
          id="gender"
          value={Filters["gender"]}
          onChange={(e) => setFilters({ gender: e.target.value })}
        >
          <option value="any">Any</option>
          <option value="F">Female</option>
          <option value="M">Male</option>
          <option value="X">Expansive</option>
        </select>
      </div>

      <div className="form-group mr-2">
        <label
          className={
            (Filters["race"] !== "any" ? "font-weight-bold" : "") + " px-2"
          }
          htmlFor="race"
        >
          Race
        </label>
        <select
          className="form-control"
          name="race"
          id="race"
          value={Filters["race"]}
          onChange={(e) => setFilters({ race: e.target.value })}
        >
          <option value="any">Any</option>
          <option>African-American</option>
          <option>Asian</option>
          <option>Caucasian</option>
          <option>Hispanic</option>
          <option>Native American</option>
        </select>
      </div>

      <div className="form-group mr-2">
        <label
          className={
            (Filters["start_date"] !== undefined ? "font-weight-bold" : "") +
            " px-2"
          }
          htmlFor="start_date"
        >
          Start Date
        </label>
        <input
          type={"date"}
          className="form-control"
          name="start_date"
          id="start_date"
          value={Filters["start_date"] || ""}
          onChange={(e) => {
            let date = e.target.value === "" ? undefined : e.target.value;
            setFilters({ start_date: date });
          }}
        />
      </div>

      <div className="form-group mr-2">
        <label
          className={
            (Filters["end_date"] !== undefined ? "font-weight-bold" : "") +
            " px-2"
          }
          htmlFor="end_date"
        >
          End Date
        </label>
        <input
          type={"date"}
          className="form-control"
          name="end_date"
          id="end_date"
          value={Filters["end_date"] || ""}
          onChange={(e) => {
            let date = e.target.value === "" ? undefined : e.target.value;
            setFilters({ end_date: date });
          }}
        />
      </div>
      <button
        className="btn btn-transparent text-primary mt-auto mb-3"
        onClick={() => {
          clearAllFilters();
        }}
      >
        Clear Filters
      </button>
    </div>
  );
}
