import { useQueryParams, withDefault, StringParam } from "use-query-params";

export default function useAllQueryParams() {
  const [query, setQuery] = useQueryParams({
    geo_type: withDefault(StringParam, "any"),
    geography: withDefault(StringParam, "any"),
    age_range: withDefault(StringParam, "any"),
    gender: withDefault(StringParam, "any"),
    race: withDefault(StringParam, "any"),
    start_date: StringParam,
    end_date: StringParam,
  });

  let clearAllQueryParams = () => {
    setQuery({
      geo_type: undefined,
      geography: undefined,
      age_range: undefined,
      gender: undefined,
      race: undefined,
      start_date: undefined,
      end_date: undefined,
    });
  };

  return [query, setQuery, clearAllQueryParams];
}
