import { useContext } from "react";
import Filters from "../Components/Filters";
import AppContext from "../Shared/Context/AppContext";
import { AgeRanges, Genders, Races, county_fips } from "../Shared/Labels";
import { op } from "arquero";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function Breakdowns() {
  return (
    <div className="p-4">
      <Filters></Filters>
      <div className="d-flex flex-wrap justify-content-between">
        <div style={{ width: "26em" }}>
          <h2 className="h5">Cards Collected by Age</h2>
          <CardCollectedByField
            field="age_range"
            labels={AgeRanges}
          ></CardCollectedByField>
        </div>
        <div style={{ width: "26em" }}>
          <h2 className="h5">Cards Collected by Gender</h2>
          <CardCollectedByField
            field="gender"
            labels={Genders}
          ></CardCollectedByField>
        </div>
        <div style={{ width: "26em" }}>
          <h2 className="h5">Cards Collected by Race</h2>
          <CardCollectedByField
            field="race"
            labels={Races}
          ></CardCollectedByField>
        </div>
        <div className="w-100">
          <h2 className="h5">Cards Collected by County</h2>
          <CardCollectedByField
            field="county_fips"
            labels={county_fips}
          ></CardCollectedByField>
        </div>
      </div>
    </div>
  );
}

function CardCollectedByField({ field, labels }) {
  let { FilteredCardCountTable } = useContext(AppContext);
  let counts = FilteredCardCountTable.groupby(field).rollup({
    count: op.sum("Count"),
  });
  let indices = counts.indices();
  let data = Array.from(indices).map((i) => {
    console.log(i);
    let fieldValue = counts.column(field).get(i);
    return {
      name: fieldValue === null ? "Unknown" : labels[fieldValue],
      Count: counts.column("count").get(i),
    };
  });
  // let data = Object.keys(labels).map((key, i) => {
  //   let fieldValue = counts.column(field).get(i);
  //   return {
  //     name: fieldValue === null ? "Unknown" : labels[fieldValue],
  //     Count: counts.column("count").get(i),
  //   };
  // });
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip cursor={{ fill: "#ebebeb" }} />
        <Bar dataKey="Count" fill="#003366" />
      </BarChart>
    </ResponsiveContainer>
  );
}
